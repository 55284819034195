<template>
  <div class="orderly-input-wrapper" style="min-height: 90px">
    <div class="input-container" :class="{'focused': focused, 'hinted': !!hint, 'has-value': !!value}">
      <label
          :class="{'hint-margin': !!hint, 'not-floating' : !focused && !value, required: required, file: type === 'file'}"
          :for="'orderly-input_' + _uid"
          class="orderly-label"
      >{{ label }}</label>

      <div v-if="!!hint" class="input-divider"/>
      <div v-if="!!hint" class="input-text hint">
        {{ hint }}
      </div>
      <div class="input-wrapper flex flex-row">
        <input
            v-if="!!mask"
            :id="'orderly-input_' + _uid"
            ref="input"
            v-mask="mask"
            :readonly="readonly"
            :required="required"
            :disabled="disabled"
            :value="value"
            :class="{
            'hint-margin': !!hint,
            'orderly-input': true,
            'value-border': !!value,
            'color-picker': type === 'color',
            'file': type === 'file'
          }"
            :type="type === 'color' ? 'text ' : type"
            :placeholder="placeholder"
            @focus="onFocus"
            @blur="onBlur"
            @keyup.enter.prevent.stop="login"
            @input="(event) => $emit('input', event.target.value)"
        >

        <input
            v-if="!mask"
            :id="'orderly-input_' + _uid"
            ref="input"
            :required="required"
            :disabled="disabled"
            :readonly="readonly"
            :value="value"
            :class="{
            'hint-margin': !!hint,
            'orderly-input': true,
            'value-border': !!value,
            'color-picker': type === 'color',
            'file': type === 'file'
          }"
            :type="type === 'color' ? 'text ' : type"
            :placeholder="placeholder"
            @keyup.enter="onEnter"
            @focus="onFocus"
            @blur="onBlur"
            @input="(event) => $emit('input', event.target.value)"
        >

        <div v-if="type === 'color'" class="color-preview" :style="{background: value}"/>

        <button v-if="type === 'file'" class="upload-button" @click.prevent="inputClick">
          Upload
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import {mask} from 'vue-the-mask'

export default {
  name: 'FormInput',
  directives: {mask},
  props: {
    value: {
      type: String,
      default: ''
    },
    readonly: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    },
    hint: {
      type: String,
      default: ''
    },
    mask: {
      type: String,
      default: ''
    },
    type: {
      default: 'text',
      type: String
    },
    required: {
      default: false,
      type: Boolean
    },
    placeholder: {
      default: null,
      type: String
    },
    disabled: {
      default: false,
      type: Boolean
    },
    onEnter: {
      default: () => ({ /* noop */}),
      type: Function
    }
  },
  data() {
    return {
      focused: false
    }
  },
  mounted() {
    this.focused = document.activeElement === this.$el
  },
  methods: {
    onFocus() {
      this.focused = true
    },
    onBlur() {
      this.focused = false
    },
    inputClick() {
      this.$refs.input.click()
    }
  }
}
</script>

<style scoped>

.input-wrapper {
  width: 100%;
}

.color-preview {
  width: 40px;
  min-width: 40px;
  height: 40px;
  min-height: 40px;
  margin-top: 30px;
  margin-left: 18px;
  border-radius: 24px;
  border: 1px #444 solid;
}

input.color-picker {
  max-width: 300px;
}

input.orderly-input::placeholder {
  color: #D2D2D2;
  opacity: 1;
}

input.orderly-input:-webkit-autofill,
input.orderly-input:-webkit-autofill:hover,
input.orderly-input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  font-family: var(--primary-font) !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 15px !important;
  line-height: 24px !important;
  -webkit-text-size-adjust: 0 !important;
  transition: font-size 5000s ease-in-out 0s;
}

.submission-container label {
  display: flex;
  flex-direction: column;
  font-family: sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
  flex: none;
  order: 0;
  align-self: flex-start;
  flex-grow: 0;
  margin: 8px 8px 0;
}

.submission-container input {
  flex: none;
  order: 0;
  align-self: flex-start;
  flex-grow: 0;
  width: 400px;
  height: 48px;
  background: #FFFFFF;
  border-radius: 8px;
  padding: 14px 20px;
  margin-top: 8px;
  outline: none;
  border: 1px solid white;
}

input.orderly-input {
  flex: none;
  order: 0;
  align-self: flex-start;
  flex-grow: 0;
  width: 400px;
  height: 48px;
  background: #FFFFFF;
  border-radius: 8px;
  padding: 14px 20px;
  margin-top: 8px;
  outline: none;
  border: 1px solid white;
}

input.file {
  display: none;
}

label.file {
  background-color: red !important;
}

.input-container.has-value > input {
  border: 1px solid #cacaca;
}

.input-container > label {
  display: flex;
  flex-direction: column;
  font-family: sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
  flex: none;
  order: 0;
  align-self: flex-start;
  flex-grow: 0;
  margin: 8px 8px 0;
}

input.orderly-input:focus {
  border: 1px solid #E56A25;
  box-sizing: border-box;
  border-radius: 8px;
}

.upload-button {
  background: #E3E3E3;
  border-radius: 4px;
  font-family: var(--primary-font);
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #333333;
  width: 120px;
  height: 33px;
  cursor: pointer;
  margin-top: 49px;
}

.input-divider {
  position: absolute;
  background: #ECECEC;
  height: 29px;
  margin: 10px 0;
  left: 48px;
  width: 1px;
}

.input-text {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 24px;
  color: #000000;
}

.hint {
  position: absolute;
  bottom: 12px;
  left: 17px;
}

.input-container {
  position: relative;
  display: block;
  height: 100%;
}

input.orderly-input.hint-margin {
  padding-left: 60px;
}

.orderly-input-wrapper {
  padding: 8px 0;
}

</style>
