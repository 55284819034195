<template>
  <button
      :class="{
      'small': size === 'small',
      'primary': size === 'primary',
      'secondary-color': color === 'secondary',
      'inverted': color === 'inverted',
    }"
      :disabled="disabled"
      @click.prevent="click"
  >
    <div class="button-text">
      {{ text }}
    </div>
  </button>
</template>

<script>

export default {
  name: 'FormButton',
  props: {
    size: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: 'Button'
    },
    color: {
      type: String,
      default: 'primary'
    },
    click: {
      type: Function,
      default: () => {
      }
    },
    disabled: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>

button {
  background: var(--menus-orange);
  color: white;
  font-size: 16px;
  font-weight: 600;
  border-radius: 8px;
  padding: 12px 20px;
  box-shadow: var(--shadow-orange);
  transition: all .25s;
}

button:hover:not([disabled]) {
  background: #E56A25;
  transform: translateY(-4px);
  box-shadow: 2px 6px 14px rgba(229, 67, 37, 0.25)
}

button:active:not([disabled]) {
  transform: translateY(0);
  background: var(--menus-orange);
  box-shadow: var(--shadow-orange);
  transition: all .10s
}

button.inverted {
  background: none;
  border: 2px solid var(--menus-orange);
  box-shadow: none;
}

button.small {
  width: 160px;
  height: 38px;
}

button.small > .button-icon {
  display: none;
}

button[disabled] {
  background: #C4C4C4 !important;
  cursor: initial;
  box-shadow: none;
}

.button-icon {
  background: #E3E3E3;
  border-radius: 2px;
  height: 24px;
  width: 24px;
  margin: 0 14px;
}

.button-text {
  display: block;
  color: #ffffff;
  margin: 0;
  font-family: sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
}

button.inverted > .button-text {
  color: var(--menus-orange)!important;
}

</style>
