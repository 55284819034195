<template>
  <div class="orderly-textfield-container flex flex-col">
    <label style="padding: 6px" :class="{required: required}" class="orderly-label" for="orderly-text-area">{{
        label
      }}</label>
    <textarea
        id="orderly-text-area"
        :value="value"
        :required="required"
        :placeholder="placeholder"
        name="text"
        @input="(event) => $emit('input', event.target.value)"
    />
  </div>
</template>

<script>
export default {
  name: 'FormTextarea',
  props: {
    value: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: 'Label'
    },
    change: {
      type: Function,
      default: () => { /* noop */
      }
    },
    placeholder: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    handleChange() {
      this.change(this.value)
    }
  }
}
</script>

<style scoped>

textarea::placeholder {
  opacity: 1;
  color: #D2D2D2;
}

.orderly-textfield-container {
  position: relative;
  border-radius: 8px;
  height: auto;
  width: 100%;
  outline: none;
  display: flex;
  flex-direction: row;
  margin: 8px 0;
}

.orderly-textfield-container > label {
  color: #000000;
  flex: none;
  order: 0;
  align-self: flex-start;
  flex-grow: 0;
  margin: 0 0;
  font-family: sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
}

.orderly-textfield-container > textarea {
  -webkit-appearance: none;
  background-color: white;
  box-sizing: border-box;
  border-radius: 8px;
  height: 150px;
  width: 100%;
  font-family: var(--primary-font);
  border: 1px solid white;
  outline: none;
  padding: 8px 16px 0;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 24px;
  color: #000000;
}

.orderly-textfield-container > textarea:focus {
  border: 1px solid #E56A25;
  box-sizing: border-box;
  border-radius: 8px;
}

</style>
