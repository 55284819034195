var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"orderly-input-wrapper",staticStyle:{"min-height":"90px"}},[_c('div',{staticClass:"input-container",class:{'focused': _vm.focused, 'hinted': !!_vm.hint, 'has-value': !!_vm.value}},[_c('label',{staticClass:"orderly-label",class:{'hint-margin': !!_vm.hint, 'not-floating' : !_vm.focused && !_vm.value, required: _vm.required, file: _vm.type === 'file'},attrs:{"for":'orderly-input_' + _vm._uid}},[_vm._v(_vm._s(_vm.label))]),(!!_vm.hint)?_c('div',{staticClass:"input-divider"}):_vm._e(),(!!_vm.hint)?_c('div',{staticClass:"input-text hint"},[_vm._v(" "+_vm._s(_vm.hint)+" ")]):_vm._e(),_c('div',{staticClass:"input-wrapper flex flex-row"},[(!!_vm.mask)?_c('input',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.mask),expression:"mask"}],ref:"input",class:{
          'hint-margin': !!_vm.hint,
          'orderly-input': true,
          'value-border': !!_vm.value,
          'color-picker': _vm.type === 'color',
          'file': _vm.type === 'file'
        },attrs:{"id":'orderly-input_' + _vm._uid,"readonly":_vm.readonly,"required":_vm.required,"disabled":_vm.disabled,"type":_vm.type === 'color' ? 'text ' : _vm.type,"placeholder":_vm.placeholder},domProps:{"value":_vm.value},on:{"focus":_vm.onFocus,"blur":_vm.onBlur,"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();$event.stopPropagation();return _vm.login($event)},"input":function (event) { return _vm.$emit('input', event.target.value); }}}):_vm._e(),(!_vm.mask)?_c('input',{ref:"input",class:{
          'hint-margin': !!_vm.hint,
          'orderly-input': true,
          'value-border': !!_vm.value,
          'color-picker': _vm.type === 'color',
          'file': _vm.type === 'file'
        },attrs:{"id":'orderly-input_' + _vm._uid,"required":_vm.required,"disabled":_vm.disabled,"readonly":_vm.readonly,"type":_vm.type === 'color' ? 'text ' : _vm.type,"placeholder":_vm.placeholder},domProps:{"value":_vm.value},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.onEnter($event)},"focus":_vm.onFocus,"blur":_vm.onBlur,"input":function (event) { return _vm.$emit('input', event.target.value); }}}):_vm._e(),(_vm.type === 'color')?_c('div',{staticClass:"color-preview",style:({background: _vm.value})}):_vm._e(),(_vm.type === 'file')?_c('button',{staticClass:"upload-button",on:{"click":function($event){$event.preventDefault();return _vm.inputClick($event)}}},[_vm._v(" Upload ")]):_vm._e()])])])}
var staticRenderFns = []

export { render, staticRenderFns }