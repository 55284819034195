import BendService from "@/services/Bend/BendService";

const UserService = {
    login,
    setAuthToken,
    getAuthToken,
    lookup: lookup,
    requestPinNewUser: requestPinNewUser,
    requestPinExistingUser: requestPinExistingUser,
    verifyPinNewUser: verifyPinNewUser,
    verifyPinExistingUser: verifyPinExistingUser,
    createUserByVerification: verifyCreateLogin,
    create: createUser,
    getActiveUser: getActiveUser,
    getHeaders: getHeaders,
}

const AUTH_TOKEN_LOCAL_STORAGE_KEY = 'authtoken';

function getHeaders() {
    return {headers: {'Authorization': `Bend ${window.localStorage.getItem('authtoken')}`}}
}

//todo(mikol): this should happen on the api
function generatePassword() {
    return Array(256)
        .fill('0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz~!@-#$')
        .map(x => x[Math.floor(crypto.getRandomValues(new Uint32Array(1))[0] / (0xffffffff + 1) * x.length)])
        .join('');
}

//todo(mikol): these should decoupled from bendservice

function lookup(phoneNumberUnformatted) {
    return BendService.postUser('_lookup', {username: phoneNumberUnformatted})
}

function createUser(phoneNumberUnformatted, password) {
    return BendService.postUser('', {username: phoneNumberUnformatted, password: password})
}

function requestPinNewUser(phoneNumberUnformatted) {
    return BendService.postUser('check_phone', {phone: phoneNumberUnformatted})
}

function verifyPinNewUser(phoneNumberUnformatted, pin) {
    return BendService.postUser('verify_pin', {phone: phoneNumberUnformatted, pin: pin})
}

function requestPinExistingUser(phoneNumberUnformatted) {
    return BendService.postUser('request_pin', {username: phoneNumberUnformatted})
}

function verifyPinExistingUser(phoneNumberUnformatted, pin) {
    return BendService.postUser('phone_login', {username: phoneNumberUnformatted, pin: pin})
}

function login(phoneNumberUnformatted, password) {
    return BendService.postUser('login', {username: phoneNumberUnformatted, password: password})
      .then(res => {
        setAuthToken(res._bmd.authtoken)
        return res
      })
}

function setAuthToken(token) {
  window.localStorage.setItem(AUTH_TOKEN_LOCAL_STORAGE_KEY, token)
}

function getAuthToken() {
  return window.localStorage.getItem(AUTH_TOKEN_LOCAL_STORAGE_KEY)
}

function getActiveUser() {
    return BendService.getActiveUser()
}

function verifyCreateLogin(phoneNumberUnformatted, pin) {
    let randomPassword = generatePassword();
    return verifyPinNewUser(phoneNumberUnformatted, pin)
        .then(() => createUser(phoneNumberUnformatted, randomPassword))
        .then(() => login(phoneNumberUnformatted, randomPassword))
        .catch(() => alert('pin invalid, not implemented'))
}

export default UserService;
