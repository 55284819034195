<template>
  <div class="w-full flex justify-center" style="min-height: 100vh;">
    <div class="submission-container flex flex-col items-start flex-grow">
      <header>
        <button @click="() => $router.push('/')" class="flex items-center">
          <img aria-label="An arrow pointing to the left" aria-hidden="true" class="mr-2" src="@/assets/back-arrow.svg">
          <span class="back-text">Go back</span>
        </button>
      </header>
      <main>
        <h1 class="mt-8 info-header">
          Smart move!
        </h1>
        <h2 class="mt-2 info-subheader">Now, let's get cookin'...</h2>
        <div class="flex flex-row md-col">
          <form ref="signupform" class="flex flex-col md-mobile-margin" style="margin-right: 20px">
            <FormInput placeholder="Human Being" :required="true" label="What's your name?*" v-model="name"/>
            <FormInput placeholder="example@gmail.com" :required="true" type="email" label="Your email address*"
                       v-model="email"/>
            <FormInput placeholder="555-555-5555" :required="true" type="phone" mask="###-###-####"
                       label="Your phone number*" v-model="phone"/>
            <FormInput placeholder="Pancake Paradise" label="What's the name of your business?" v-model="businessName"/>
            <FormInput placeholder="www.pancakes.com" label="Your website, if you have one" v-model="website"/>
            <FormInput placeholder="@pancakeparadise" label="Instagram username, if you have one"
                       v-model="instagramUsername"/>
          </form>
          <div class="flex flex-col md-mobile-margin" style="margin-top: 16px; margin-left: 20px;">
            <p>
              To help us build your menu, please upload your menu
              files below in the best format you have available. We
              prefer PDF or a document format, but images are fine
              too. If you don’t have the menu files, please upload some clear
              photos of each page. Also, please upload your logo.
            </p>

            <label style="display: none" aria-hidden="true">
              File Upload
              <input @change="handleSelectedFile" id="logo-file-input" type="file" ref="file" multiple>
            </label>
            <button @click.prevent="triggerFileSelection"
                    @drop.prevent="handleDroppedFile"
                    @dragover.prevent="() => { /* noop needed for chrome to handle drop */ }"
                    @dragenter.prevent="setDragActive"
                    @dragleave.prevent="setDragInactive"
                    @dragexit.prevent="setDragInactive"
                    @dragend.prevent="setDragInactive"
                    class="logo-button-container upload-text">

              {{ dragActive ? 'Drop here to upload' : 'Drag files in or click to select' }}

            </button>

            <div class="upload-item" v-for="uploadItem in uploadItems" :key="uploadItem.id">

              <div class="flex">

                <div class="upload-item-icon-container">
                  <img class="rotating" v-if="!uploadItem.done" src="@/assets/loading-spinner.svg" alt="Loading Icon"/>
                  <img v-if="uploadItem.success" src="@/assets/success-icon.svg" alt="Success Icon"/>
                  <img v-if="uploadItem.error" src="@/assets/error-icon.svg" alt="Error Icon"/>
                </div>

                <div class="flex flex-col" v-if="!uploadItem.done">
                  <div class="loading-bar-container">
                    <p class="upload-text-progress">{{ Math.floor(uploadItem.percentUploaded) }}%</p>
                    <div :style="{transform: `scaleX(${Math.floor(uploadItem.percentUploaded) / 100})`}"
                         class="loading-bar"/>
                  </div>
                </div>

                <span v-if="uploadItem.src" class="upload-title-text">{{ getFileName(uploadItem.src) }}</span>

              </div>
              <button @click="removeFile(uploadItem)" :aria-label="`Remove uploaded file: ${uploadItem.src}`">
                <img src="@/assets/trash-icon.svg" alt="Trash Icon"/>
              </button>
            </div>

            <FormTextarea placeholder="Just to say hi..." style="margin-top: 16px"
                          label="Have a question? Or is there something you'd like us to know?" v-model="questions"/>
          </div>
        </div>
        <div class="w-full flex justify-center">
          <FormButton :disabled="uploadingFiles" style="margin-top: 40px; width: 180px" :click="submit"
                      :text="uploadingFiles ? 'Uploading...' : 'Continue'"/>

        </div>
      </main>
      <Footer/>
    </div>
  </div>
</template>

<script>
import BendRpcService, {AUTH_TYPES} from "@/services/Bend/BendRpcService";
import Footer from "@/components/Footer";
import FormInput from "@/components/form/FormInput";
import FormButton from "@/components/form/FormButton";
import FormTextarea from "@/components/form/FormTextarea";
import BendBlobService from "@/services/Bend/BendBlobService";
import UserService from "@/services/UserService";

export default {
  name: "Signup",
  components: {
    FormTextarea,
    FormButton,
    FormInput,
    Footer
  },
  data() {
    return {
      uploadedSeq: 0,
      uploadItems: [],
      dragActive: false,
      name: '',
      email: '',
      phone: '',
      businessName: '',
      website: '',
      instagramUsername: '',
      questions: '',
    }
  },
  computed: {
    uploadingFiles() {
      return this.uploadItems.some(item => !item.done)
    }
  },
  mounted() {
    window.scrollTo(0, 0)
  },
  methods: {
    triggerFileSelection() {
      console.log('clicky')
      this.$refs.file.click();
    },
    removeFile(file) {
      this.uploadItems = this.uploadItems.filter(item => item.id !== file.id)
    },
    handleDroppedFile(e) {
      this.handleFileUpload(e.dataTransfer.files)
    },
    handleSelectedFile(e) {
      this.handleFileUpload(e.target.files)
    },
    handleFileUpload(files) {

      files.forEach((file) => {

        this.dragActive = false;

        let uploadItem = {
          id: ++this.uploadedSeq,
          percentUploaded: 0,
        }

        this.uploadItems.push(uploadItem)

        UserService.login('onboard-user', 'onboard-user')
          .then(() => BendBlobService.upload(file, 'menu', ({percent}) => this.$set(uploadItem, 'percentUploaded', percent)))
          .then((res) => {
            this.$set(uploadItem, 'src', res.url)
            this.$set(uploadItem, 'success', true)
          })
          .catch(() => this.$set(uploadItem, 'failed', true))
          .finally(() => this.$set(uploadItem, 'done', true))

      });

      this.$refs.file.value = null;

    },
    setDragActive() {
      this.dragActive = true;
    },
    setDragInactive() {
      this.dragActive = false;
    },
    getFileName(src) {
      let pieces = src.split('/')
      return pieces[pieces.length - 1]
    },
    submit() {

      if (!this.$refs["signupform"].reportValidity()) {
        return;
      }

      BendRpcService.call('inquire', {
        name: this.name,
        email: this.email,
        phone: this.phone,
        businessName: this.businessName,
        website: this.website,
        instagramUsername: this.instagramUsername,
        questions: this.questions,
        todayDay: new Date().toDateString(),
        fileUpload: this.imgSrc,
        plan: this.$route.query.plan,
        files: this.uploadItems.map(item => item.src)
      }, AUTH_TYPES.APP)
        .then(() => this.$router.push('/thanks'))
        .catch(() => this.$router.push('/error'))
    }
  }
}
</script>

<style scoped>

.upload-item {
  height: 34px;
  width: 100%;
  /*border: solid 1px var(--gray-300);*/
  border-radius: 8px;
  border-style: dot-dash;
  margin-top: 14px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.upload-text-progress {
  color: white;
  font-size: 11px;
  position: absolute;
  text-align: center;
  z-index: 10;
  bottom: 3px;
  height: 100%;
  width: 100%;
  filter: saturate(10);
  overflow: hidden;
  text-overflow: ellipsis;
}

.upload-item-icon-container > img {
  margin-right: 8px;
}

.upload-title-text {
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  text-align: center;
  color: #000000;
  max-width: 300px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

@-webkit-keyframes rotating /* Safari and Chrome */
{
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.rotating {
  -webkit-animation: rotating 2s linear infinite;
  -moz-animation: rotating 2s linear infinite;
  -ms-animation: rotating 2s linear infinite;
  -o-animation: rotating 2s linear infinite;
  animation: rotating 2s linear infinite;
}

.loading-bar-container {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  border-radius: 8px;
  width: 300px;
  height: 14px;
  background: var(--gray-300);
  overflow: hidden;
  position: relative;
}

.loading-bar {
  transition: all .1s;
  transform-origin: left;
  -webkit-transform-origin: left;
  height: 100%;
  background: var(--menus-orange);
  border-radius: 8px;
}

.submission-container {
  max-width: 840px;
  margin-top: 40px;
}

#logo-file-input {
  display: none;
}

.back-text {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #222222;
  flex: none;
  order: 1;
  align-self: center;
  flex-grow: 0;
  margin: 4px 0;
}

.logo-button-container {
  margin-top: 26px;
  background: none;
  cursor: pointer;
  width: 100%;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed #C4C4C4;
  box-sizing: border-box;
  border-radius: 8px;
  outline: none;
  transition: all .5s;
}

.logo-button-container:hover {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08), 0px 0.5px 0px rgba(0, 0, 0, 0.12);
}

.upload-text {
  font-family: sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: var(--gray-700);
}

.preview-image {
  display: block;
  width: auto;
  object-fit: contain;
  max-width: 100%;
  height: auto;
  max-height: calc(100% - 12px);
  margin: 6px 0;
  pointer-events: none;
}

@media only screen and (max-width: 920px) {

  .info-header, .info-subheader {
    margin-left: 20px;
  }

  .md-col {
    flex-direction: column !important;
    align-items: center;
  }

  .submission-container {
    align-items: center !important;
  }

  .md-mobile-margin {
    margin-left: 0 !important;
    margin-right: 0 !important;
    padding-left: 18px;
    padding-right: 18px;
    width: 100%;
    align-items: center;
  }

  .submission-container >>> input {
    width: 100%;
  }

  .submission-container >>> .orderly-input-wrapper {
    width: 100%;
  }

  .submission-container >>> label {
    margin-top: 18px;
  }

  .info-header {
    font-size: 48px;
  }

}

</style>
