import BendService from "@/services/Bend/BendService";

const BendRpcService = {
    call: call
}

export const AUTH_TYPES = {
    BEND: 'BEND',
    APP: 'BASIC',
}

function call(event, payload, authType = AUTH_TYPES.BEND) {
    return BendService.$post(`/rpc/${BendService.getAppKey()}/custom/${event}`,
        payload || {},
        {headers: authType === AUTH_TYPES.BEND ? BendService.getUserTokenAuth() : BendService.getAppAuth()}
    )
}

export default BendRpcService;
