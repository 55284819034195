import BendService from "@/services/Bend/BendService";

const BendBlobService = {
  upload: upload,
}

function uploadBendFile(url, file, onUploadProgress = () => ({ /* defaults to noop */})) {

  const READYSTATE = {
    UNSENT: 0,
    OPENED: 1,
    HEADERS_RECEIVED: 2,
    LOADING: 3,
    DONE: 4
  }

  return new Promise(function (resolve) {

    var xhr = new XMLHttpRequest();
    xhr.file = file;

    xhr.upload.onprogress = function (e) {
      var done = e.position || e.loaded, total = e.totalSize || e.total;
      onUploadProgress({
        done,
        total,
        percent: (Math.floor(done / total * 1000) / 10)
      })
    };

    xhr.onreadystatechange = function (e) {
      if (READYSTATE.DONE === this.readyState) {
        console.log(['xhr upload complete', e]);
        resolve(e.currentTarget.file)
      }
    };

    xhr.open('put', url, true);
    xhr.setRequestHeader('Authorization', BendService.getUserTokenAuth().Authorization);
    xhr.setRequestHeader('Content-Type', file.type);
    xhr.send(file);

  })

}

function createBendBlob(file, workflow) {
  return BendService.$post(`/blob/${BendService.getAppKey()}/?tls=true`, {
    _filename: file.name,
    mimeType: file.type,
    size: file.size,
    _public: true,
    ...(workflow && {_workflow: workflow}),
  }, {
    headers: {...BendService.getUserTokenAuth()}
  })
}

function upload(file, workflow, onUploadProgress = () => ({ /* defaults to noop */ })) {
  var uploadUrl, downloadUrl;
  return createBendBlob(file, workflow)
    .then(response => {
      uploadUrl = response?._uploadUrl;
      downloadUrl = response?._downloadUrl;
      return uploadBendFile(uploadUrl, file, onUploadProgress)
    })
    .then(() => ({url: downloadUrl}))
}

export default BendBlobService;
